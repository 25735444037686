<template lang="pug">
.auth(:style="{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.81), rgba(0, 0, 0, 0.8)), url('/img/bg-app.jpg') `}")
	.container.section.auth-container
		AuthCard(:isModal="false")
</template>

<script setup lang="ts">
import { useUserStore } from '../../pinia/userStore'
import { useAudioPlayerStore } from '../../pinia/player/audioPlayerStore'
import { useQueueStore } from '../../pinia/queueStore'
import { useMediaFileStore } from '../../pinia//mediaFileStore'
import { usePlayerStore } from '~/pinia/player/karaokePlayer'
import type { MiddlewareKey } from '#build/types/middleware'

const userStore = useUserStore()
const { showDemoPlayer } = storeToRefs(userStore)
const { segmentEvent } = useSegment()

definePageMeta({
	layout: 'view-only',
	middleware: 'authed-root-guard' as MiddlewareKey
})

// Reset everything if in preview mode
if (showDemoPlayer.value) {
	const karaokeStore = usePlayerStore()
	const audioPlayer = useAudioPlayerStore()
	const queueStore = useQueueStore()
	const mediaFileStore = useMediaFileStore()
	const variantStore = useVariantStore()
	const { setShowMiniPlayer } = karaokeStore
	setShowMiniPlayer(false)
	audioPlayer.$reset()
	queueStore.$reset()
	audioPlayer.$reset()
	mediaFileStore.$reset()
	variantStore.$reset()
}

const sendSignUpEvent = () => {
	const { storeQueryParams, getStoredQueryParams } = useQueryParams()
	storeQueryParams()
	const utmTags = getStoredQueryParams()
	segmentEvent('User Sign Up Started', {
		...utmTags
	})
}

onMounted(() => {
	const { $audioPlayer } = useNuxtApp()
	const queueStore = useQueueStore()
	if ($audioPlayer) {
		queueStore.stopSong()
	}
	sendSignUpEvent()
})

// Meta
const { setMetaTitle, setMetaDescription } = useMetaTags()
const { t } = useI18n()

const pageTitle = t('meta.login.title')
const metaTitle = setMetaTitle(pageTitle)
const metaDesc = setMetaDescription(t('meta.general.description'))

useHead({
	title: pageTitle,
	meta: [
		...metaTitle,
		...metaDesc
	]
})
</script>

<style scoped lang="sass">
.auth
	height: auto
	min-height: 100vh
	background-position: center
	background-repeat: no-repeat
	background-size: cover
	padding: $spacing-80 0
.auth-container
	display: flex
	justify-content: center
</style>
